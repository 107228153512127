<template>
  <tr>
    <td style="width:10.5%;">
      <v-text-field
        v-model="transport_item.number"
        outlined
        dense
        hide-details="auto"
        class="pr-1"
        background-color="white"
        @input="(v) => transport_item.number = v.replace(' ', '')"
        @keypress.enter="showVehiclesCatalog"
        @change="onChange"
      />
    </td>
    <td style="width:19%;">
      <v-text-field
        v-model="transport_item.vin"
        class="pr-1"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        @change="onChange"
      />
    </td>
    <td style="width:11%;">
      <v-autocomplete
        v-model="transport_item.country_letter"
        auto-select-first
        outlined
        dense
        hide-details="auto"
        background-color="white"
        :items="countries"
        item-text="text"
        item-value="letterCode"
        :filter="filterBySearchField"
        @change="onChange"
      />
    </td>
    <td style="width:11%;">
      <v-autocomplete
        v-model="transport_item.vehicle_type"
        auto-select-first
        background-color="white"
        dense
        outlined
        hide-details="true"
        item-value="code"
        item-text="text"
        :items="vehicleTypes"
        :filter="filterBySearchField"
        @change="onChange"
      />
    </td>
    <td style="width:20%;">
      <v-autocomplete
        v-model="transport_item.transport_mark_code"
        item-text="text"
        item-value="code"
        :items="nsiTransportMarks"
        auto-select-first
        background-color="white"
        dense
        outlined
        hide-details="true"
        :filter="filterBySearchField"
        @change="onChange"
      />
    </td>
    <td
      class="d-flex"
    >
      <v-btn
        max-width="36px"
        min-width="36px"
        color="transparent"
        class="elevation-0"
        @click="deleteReloadTransport"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        class="document-add elevation-0"
        color="transparent"
        min-width="36px"
        max-width="36px"
        @click="createReloadTransport"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import { filterBySearchFieldMixin } from "@/mixins/catalogs"
import {catalogValueSelected, showCatalog} from "@/events/statistics";
import {mapGetters} from "vuex";
import highlightFieldsChild from "@/mixins/control/highlight-fields-child";
export default {
  mixins: [filterBySearchFieldMixin, highlightFieldsChild],
  props: {
    item: {
      required: true,
      type: Object
    },
    idx: {
      required: true,
      type: Number
    },
    reloadItem: {
      required: true,
      type: Object
    },
    reloadItemIndex: {
      required: true,
      type: Number
    },
  },
  data:() => ({
    block_id: null,
    transport_item: {
      id: null,
      declaration_id: null,
      number:  null,
      reload_id:  null,
      country_letter: null,
      country_name:  null,
      vehicle_type:  null,
      transport_mark_code:  null,
      transport_mark_name:  null,
      vin:  null,
    }
  }),
  computed:{
    ...mapGetters({
      user: 'auth/getUser',
      countries: "catalogs/getNsiCountries",
      vehicleTypes: "catalogs/getNsiVehicleTypes",
      customsPoints: "catalogs/getNsiCustomsPoints",
      nsiTransportMarks: "transit/getNsiTransportMarks",
    }),
    divisionId() {
      return this.user?.user?.activeDivisionId;
    }
  },
  watch: {
    item: {
      handler(newVal) {
        this.block_id = `transit-reload-transport-item-${this.item.id}`;
        for (let key in newVal) {
          if (key in this.transport_item) {
            this.transport_item[key] = newVal[key];
          }
        }
      },
      immediate: true,
      deep: true,
    }
  },
  created() {
    catalogValueSelected.subscribe(this.updatesResolver, [
      'transport/number',
    ])
  },
  methods: {
    deleteReloadTransport(){
      this.$emit('delete', {index:this.idx, id:this.transport_item.id})
    },
    createReloadTransport(){
      this.$emit('create')
    },
    onChange() {
      this.updateReloadTransport()
      this.readyToUpdate()
    },
    updateReloadTransport(){
      this.$emit('update', {item: this.transport_item, index: this.idx})
    },
    updatesResolver({id, field, code, item}) {
      if (id === this.transport_item.id) {
        if (code && field) {
          if (field === 'transport/number') {
            const {number, country_letter, vehicle_type, transport_mark_code, transport_mark_name, vin} = item
            this.transport_item = {
              ...this.transport_item,
              number,
              country_letter,
              vehicle_type,
              transport_mark_code,
              transport_mark_name,
              vin
            }
          } else {
            const [, key] = field.split('/')
            this.transport_item[key] = code
          }
          this.onChange()
        }
      }
    },
    showVehiclesCatalog() {
      showCatalog.trigger({
        type: "vehicles",
        id: this.transport_item.id,
        search: this.transport_item.number,
        field: "transport/number"
      });
    },
    readyToUpdate() {
      this.$emit('ready')
    },

  }
}
</script>

<style scoped>
td {
  padding-left: 0 !important;
  border-bottom: none !important;
  padding-bottom: 10px !important;
}
</style>
