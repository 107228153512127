<template>
  <v-col
    cols="4"
  >
    <v-row class="mb-2">
      <v-col
        cols="3"
        class="pr-0"
      >
        <label>
          Код валюты
          <v-menu offset-x>
            <template #activator="{ on, attrs }">
              <v-icon
                tabindex="-1"
                class="mx-0"
                small
                dense
                v-bind="attrs"
                v-on="on"
              >mdi-tune</v-icon>
            </template>
            <v-list>
              <v-list-item @click="copyToAll('invoice_currency_letter', item.invoice_currency_letter )">
                Применить ко всем товарам
              </v-list-item>
              <v-list-item @click="copy('invoice_currency_letter', item.invoice_currency_letter, 'apply')">
                Применить к выбранным товарам
              </v-list-item>
            </v-list>
          </v-menu>
        </label>
        <v-autocomplete
          ref="invoice_currency_letter"
          v-model="item.invoice_currency_letter"
          auto-select-first
          :items="currencies"
          item-text="letterCode"
          item-value="letterCode"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          :filter="filterBySearchField"
          @change="updateCustomsCost(null)"
        />
      </v-col>
      <v-col class="pr-0">
        <label> Цена /стоим. товара </label>
        <input-numeric
          ref="invoice_cost"
          v-model="item.invoice_cost"
          :fraction="2"
          class="mx-1"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          @change="onInvoiceCostChange"
        />
      </v-col>
      <v-col>
        <label>
          Тамож. стоимость
          <v-menu offset-x>
            <template #activator="{ on, attrs }">
              <v-icon
                class="mx-1"
                tabindex="-1"
                small
                dense
                v-bind="attrs"
                v-on="on"
              >mdi-tune
              </v-icon>
            </template>
            <v-list>
              <v-list-item @click="recalculateCustomsCost">
                Рассчитать таможенную стоимость для всех товаров
              </v-list-item>
              <v-list-item @click="clearCustomsCost">
                Очистить таможенную стоимость для всех товаров
              </v-list-item>
            </v-list>
          </v-menu>
        </label>
        <input-numeric
          ref="customs_cost"
          v-model="item.customs_cost"
          :fraction="2"
          class="ml-1"
          outlined
          dense
          disabled
          hide-details="auto"
          background-color="grey lighten-2"
        />
      </v-col>
    </v-row>
    <template v-if="item.ware_payment_json.length">
      <ware-calculated-payments
        ref="ware_payment_json"
        :payments="warePaymentsPretty"
        @clear="clearPayments"
      />
    </template>
    <div
      v-else
      ref="ware_payment_json"
      class="payments"
    >
      <v-radio-group
        v-show="availableExcises"
        :value="selectedExcise"
        hide-details="true"
        @change="selectOption"
      >
        <v-radio
          v-for="(radio, i) in availableExcises.data"
          :key="i"
          class="mb-2 mr-0"
          color="#000"
          :value="radio"
          dense
        >
          <template #label>
            <div class="text-body-2 d-flex justify-space-between">
              <v-tooltip
                v-if="radio.description.length > 30"
                max-width="400"
                top
              >
                <template #activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >{{ radio.description.slice(0, 30) }}...
                  </span>
                </template>
                {{ radio.description }}
              </v-tooltip>
              <span v-else>{{ radio.description }}</span>
              <span class="black--text font-weight-medium">{{
                radio.rateDescription
              }}</span>
            </div>
          </template>
        </v-radio>
      </v-radio-group>
    </div>
  </v-col>
</template>

<script>
import {mapGetters} from "vuex";
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import {addPromise, exciseChanged, tnvedCodeChanged, updateGoodParameters,} from "@/events/statistics";
import {getCustomsCost, normalizeFloat, prettifyNumber} from "@/helpers/math";
import cloneDeep from "lodash.clonedeep";
import formatDate from "@/mixins/format-date.js";
import {eventBus} from "@/bus.js";
import HighlightFieldWareSector from "@/mixins/control/highlight-field-ware-sector";
import copyFieldMixin from "@/mixins/copy-field.mixin";
import InputNumeric from "@/components/ui/input-numeric.vue";
import WareCalculatedPayments from "@/components/documents/ware/ware-calculated-payments.vue";
import {readyToUpdateInjector} from "@/helpers/injectors";
import {getExciseNeed} from "@/helpers/ware-payments";

export default {
  components: {WareCalculatedPayments, InputNumeric},
  mixins: [filterBySearchFieldMixin, formatDate, HighlightFieldWareSector, copyFieldMixin],
  inject:{
    readyToUpdate:{
      from:readyToUpdateInjector
    }
  },
  props: {
    index: {
      required: true,
      type: Number,
    },
    info: {
      type: Object,
      required: true,
    },
    visited: {
      required: true,
      type: Boolean,
    },
    hasChanges: {
      required: true,
      type: Boolean,
    },
    availableExcises: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    item: {
      id: null,
      invoice_currency_letter: null,
      invoice_cost: null,
      customs_cost: null,
      ware_payment_json: [],
      excise_json: {
        excise_need: null,
        excise_id: null,
        transit_excise_id: null,
        rate_description: null,
        description: null,
      },
    },
  }),
  computed: {
    ...mapGetters({
      selected: "transit/getSelected",
      currencies: "catalogs/getNsiCurrencies",
      shipmentIndex:'transit/getShipmentIndex'
    }),
    warePaymentsPretty(){
      return this.item.ware_payment_json.map((payment) => {
        return {
          ...payment,
          basisPretty:prettifyNumber(payment.basis),
          sumPretty: prettifyNumber(payment.sum),
        }
      })
    },
    selectedWares(){
      return this.selected.ware_shipments[this.shipmentIndex]?.wares ?? []
    },
    selectedExcise() {
      if (this.availableExcises.type === 1) {
        return (
          this.availableExcises?.data?.find(
            (i) => i.id === this.info.excise_json.transit_excise_id
          ) ?? null
        );
      } else if (this.availableExcises.type === 2) {
        return (
          this.availableExcises?.data?.find(
            (i) => i.tarifId === this.info.excise_json.excise_id
          ) ?? null
        );
      } else {
        return null;
      }
    },
  },
  watch: {
    selectedWares: {
      handler() {
        this.setFields();
      },
      deep: true,
      immediate: true,
    },
    item: {
      handler() {
        if (this.visited) {
          this.updateEditableGoodsItem();
        }
      },
      deep: true,
    },
  },
  created() {
    tnvedCodeChanged.subscribe(this.onNewCode);
    updateGoodParameters.subscribe(this.onParamsChange);
  },
  beforeDestroy() {
    tnvedCodeChanged.unsubscribe();
    updateGoodParameters.unsubscribe();
  },
  methods: {
    clearCustomsCost(){
      this.copyToAll('customs_cost', null)
    },
    recalculateCustomsCost() {
      eventBus.$emit("recalculateCustomsCost");
    },
    async onParamsChange({ id, payload }) {
      if (id === this.item.id) {
        const { invoice_cost } = payload;
        this.item.invoice_cost = invoice_cost;
        await this.updateCustomsCost();
        this.readyToUpdate();
      }
    },
    onNewCode({ id, code }) {
      if (id === this.item.id) {
        this.updateAvailableExcises(code);
      }
    },
    // получаем справочник по акцизам
    updateAvailableExcises(code) {
      // Важно! Принимаем код для того чтобы не делать settimeout
      // Компонент Сode эмитит сообщение раньше чем вызовется  watch -> updateInfoInComponent
      // Поэтому как альтернатива передается актуальный код ТНВЭД
      const tn_ved = code ?? this.info.tn_ved;
      if (tn_ved) {
        const promise = new Promise((resolve, reject) => {
          const payload = {
            tn_ved,
            date: this.formatDate(
              this.selected.about_declaration.declaration_date
            ),
          };
          this.$store
            .dispatch("transit/getAvailableExcise", payload)
            .then((value) => {
              this.$emit("update:available-excises", value);

              if (value?.data?.length) {
                this.updateExcise(value);
                this.updateExciseMeasure(value);
              } else {
                this.clearExcise();
              }

              resolve(value);
            })
            .catch((e) => {
              if (e.color) {
                this.$snackbar({
                  text: e.message,
                  color: e.color ?? "red",
                  top: false,
                });
                reject(e);
              }
              this.$snackbar({
                text: "Ошибка загрузки акцизов",
                color: "red",
                top: false,
              });
              reject(e);
            });
        });
        addPromise.trigger({ id: this.item.id, promise });
      } else {
        this.clearExcise();
      }
    },
    clearExcise() {
      this.$emit("update:available-excises", {});
      const data = {
        description: null,
        excise_id: null,
        excise_need: null,
        rate_description: null,
        transit_excise_id: null,
      };
      this.item.excise_json = data;
      this.readyToUpdate();
    },
    updateExciseMeasure(payload) {
      const { data } = payload;
      const [value] = data;
      const { measureUnitDigit, factor2, specificMeasureUnitDigit } = value;
      exciseChanged.trigger({
        id: this.info.id,
        measureUnitDigit,
        specificMeasureUnitDigit: factor2 ? specificMeasureUnitDigit : this.info.specific_measure_unit_digit
      });
    },
    updateExcise(payload) {
      const { data } = payload;
      const [value] = data;
      this.item.excise_json = {
        description: value.description,
        excise_id: value.tarifId,
        excise_need: getExciseNeed(value),
        rate_description: value.rateDescription,
        transit_excise_id: value.id,
      };
    },
    onInvoiceCostChange(value) {
      this.replaceFloatNumbers({ value, prop: "invoice_cost", afterPoint: 2 }); // Нормализуем значение
      this.updateCustomsCost(); // Обновляем стат стоимость
    },
    updateCustomsCost() {
      const promise = new Promise((resolve, reject) => {
        const date = this.selected.about_declaration.declaration_date;
        getCustomsCost
          .call(this, {
            date,
            code: this.item.invoice_currency_letter,
            cost: this.item.invoice_cost,
          })
          .then((customsCost) => {
            this.item.customs_cost = customsCost;
            return resolve(customsCost);
          })
          .catch((err) => reject(err));
      });
      addPromise.trigger({ id: this.info.id, promise });
      return promise;
    },
    replaceFloatNumbers({ value, prop, afterPoint = null }) {
      this.item[prop] = normalizeFloat({ value, afterPoint });
    },
    clearPayments() {
      this.item.ware_payment_json = [];
      this.readyToUpdate();
    },
    setFields() {
      const ware = cloneDeep(this.selectedWares[this.index]);
      Object.keys(this.item).forEach((key) => {
        if (key in ware) {
          this.item[key] = ware[key];
        }
      });
    },
    updateEditableGoodsItem() {
      this.readyToUpdate();
      this.$emit("update:info", this.item);
    },
    selectOption(value) {
      this.item.excise_json = {
        description: value.description,
        excise_id: value.tarifId,
        excise_need: getExciseNeed(value),
        rate_description: value.rateDescription,
        transit_excise_id: value.id,
      }
      this.readyToUpdate();
      exciseChanged.trigger({
        id: this.info.id,
        measureUnitDigit: value.measureUnitDigit,
        specificMeasureUnitDigit: value.factor2 ? value.specificMeasureUnitDigit : this.info.specific_measure_unit_digit
      });
    },
  },
};
</script>
