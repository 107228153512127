<template>
  <v-container
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
  >
    <v-row>
      <v-col
        cols="12"
        class="pt-0"
      >
        <div
          class="statistics-box pa-4"
          :class="{'blocked-box' : disabledView}"
        >
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col
                  cols="10"
                  class="pb-0"
                >
                  <div class="font-weight-bold d-flex align-center mt-2">
                    Общие характеристики товара
                  </div>
                </v-col>
                <v-col
                  cols="2"
                  class="text-end"
                >
                  <v-btn
                    min-width="36px"
                    max-width="36px"
                    color="#5CB7B1"
                    class="ml-1"
                    @click="togglePin"
                  >
                    <v-icon>
                      {{ pinned.includes(pinName) ? "mdi-pin-off" : "mdi-pin" }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="1"
                  class="pb-0 "
                >
                  <label>
                    5. Товаров
                    <v-text-field
                      :value="selectedWares.length"
                      class="mr-1"
                      outlined
                      dense
                      hide-details="auto"
                      disabled
                      background-color="grey lighten-2"
                    />
                  </label>
                </v-col>
                <v-col class="pr-0">
                  <label>6. Всего мест</label>
                  <div class="d-flex">
                    <v-text-field
                      ref="cargo_place_quantity"
                      v-model="about_wares.cargo_place_quantity"
                      :error="isQuantityError"
                      class="mr-1"
                      outlined
                      dense
                      hide-details="auto"
                      background-color="white"
                    />
                    <span class="text-body-1 mx-1 mt-2">/</span>
                    <v-text-field
                      ref="cargo_place_quantity"
                      v-model="cargoPlaceQuantitySum"
                      disabled
                      class="mr-1"
                      outlined
                      dense
                      hide-details="auto"
                      background-color="grey lighten-2"
                    />
                  </div>
                </v-col>
                <v-col style="max-width: 150px">
                  <label>Вес брутто</label>
                  <input-numeric
                    :value="grossWeightSum"
                    class="mr-1"
                    outlined
                    dense
                    hide-details="auto"
                    disabled
                    background-color="grey lighten-2"
                  />
                </v-col>
                <v-col
                  cols="3"
                  c
                >
                  <label>22. Валюта и общая стоимость</label>
                  <v-row>
                    <v-col cols="4">
                      <v-autocomplete
                        ref="invoice_currency_letter"
                        v-model="about_wares.invoice_currency_letter"
                        auto-select-first
                        :items="currencies"
                        item-text="letterCode"
                        item-value="letterCode"
                        outlined
                        dense
                        hide-details="auto"
                        background-color="white"
                        :filter="filterBySearchField"
                      />
                    </v-col>
                    <v-col cols="7">
                      <input-numeric
                        ref="invoice_cost"
                        v-model="about_wares.invoice_cost"
                        class="mr-1"
                        outlined
                        dense
                        hide-details="auto"
                        background-color="white"
                        :fraction="2"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col>
                  <label>Общая ТС
                    <v-menu offset-x>
                      <template #activator="{ on, attrs }">
                        <v-icon
                          tabindex="-1"
                          class="mx-1"
                          small
                          dense
                          v-bind="attrs"
                          v-on="on"
                        >mdi-tune
                        </v-icon>
                      </template>
                      <v-list>
                        <v-list-item @click="clearWaresCustomsCost">
                          Очистить таможенную стоимость
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </label>
                  <div class="d-flex">
                    <input-numeric
                      ref="customs_cost"
                      :value="about_wares.customs_cost"
                      class="mr-1"
                      outlined
                      dense
                      disabled
                      hide-details="auto"
                      background-color="grey lighten-2"
                    />
                    <v-btn
                      max-width="40px"
                      min-width="40px"
                      height="40px"
                      class="ml-2 mb-1"
                      color="#5CB7B1"
                      dense
                      @click="recalculateCustomsCost"
                    >
                      <v-icon>mdi-calculator</v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <v-col>
                  <label>
                    Страна отправления
                    <v-autocomplete
                      ref="dispatch_country_letter"
                      v-model="about_wares_json.dispatch_country_letter"
                      auto-select-first
                      outlined
                      dense
                      hide-details="auto"
                      background-color="white"
                      :filter="filterBySearchField"
                      :items="countries"
                      item-text="text"
                      item-value="letterCode"
                    />
                  </label>
                </v-col>
                <v-col>
                  <label>
                    Страна назначения
                    <v-autocomplete
                      ref="destination_country_letter"
                      v-model="about_wares_json.destination_country_letter"
                      auto-select-first
                      outlined
                      dense
                      hide-details="auto"
                      background-color="white"
                      :filter="filterBySearchField"
                      :items="countries"
                      item-text="text"
                      item-value="letterCode"
                    />
                  </label>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { getWaresParamTotal } from "@/helpers/math";
import { normalizeFloat } from "@/helpers/math";
import { eventBus } from "@/bus.js";
import { highlightField } from "@/helpers/control";
import { about_wares as onAboutWares } from "@/events/statistics/control";

import {getCountryNameByCode} from "@/helpers/catalogs";
import InputNumeric from "@/components/ui/input-numeric.vue";
import {copyToAllSucceed} from "@/events/common";
import copyFieldMixin from "@/mixins/copy-field.mixin";

export default {
  components: {InputNumeric},
  mixins: [blockAutoUpdate, filterBySearchFieldMixin, copyFieldMixin],
  data: () => ({
    pinName: "goods-character",
    block_id: "transit-goods-character",
    about_wares: {
      ware_shipment_id:null,
      cargo_place_quantity: null,
      customs_cost: null,
      invoice_cost: null,
      invoice_currency_letter: "",
    },
    about_wares_json:{
      dispatch_country_letter: null,
      dispatch_country_name: null,
      destination_country_letter: null,
      destination_country_name: null
    }
  }),
  computed: {
    ...mapGetters({
      selected: "transit/getSelected",
      currencies: "catalogs/getNsiCurrencies",
      pinned: "ui/getPinned",
      requests: "transit/getRequests",
      shipmentIndex:'transit/getShipmentIndex',
      countries:'catalogs/getNsiCountries',
      disabledView:'transit/getVisibility'
    }),
    selectedWares(){
      return this.selected.ware_shipments[this.shipmentIndex]?.wares ?? []
    },
    cargoPlaceQuantitySum() {
      return getWaresParamTotal(this.selectedWares, "cargo_place_quantity");
    },
    grossWeightSum() {
      return getWaresParamTotal(this.selectedWares, "gross_weight");
    },
    isQuantityError(){
      return (this.about_wares?.cargo_place_quantity ?? 0) !== this.cargoPlaceQuantitySum
    }
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
      immediate:true
    },
    shipmentIndex(){
      this.setFields();
    },
    "about_wares.cargo_place_quantity": {
      handler(nv) {
        const result = nv === null || nv === "" ? "" : Number.parseInt(nv);
        this.$nextTick(() => (this.about_wares.cargo_place_quantity = result));
      },
    },
  },
  created() {
    onAboutWares.subscribe(this.highlightField);
    copyToAllSucceed.subscribe(this.onCopyToAllResult)
    eventBus.$on("recalculateCustomsCost", this.recalculateCustomsCost);
  },
  beforeDestroy() {
    onAboutWares.unsubscribe();
    copyToAllSucceed.unsubscribe()
    eventBus.$off("recalculateCustomsCost", this.recalculateCustomsCost);
  },
  methods: {
    onCopyToAllResult({success, customsCostCleared}){
      if(success && customsCostCleared){
        this.clearCustomsCost()
      }
    },
    clearWaresCustomsCost(){
      this.copyToAll('customs_cost', null)
    },
    clearCustomsCost(){
      this.about_wares.customs_cost = null
      this.uploadData()
        .then(() => this.$success('Общая ТС обновлена'))
        .catch(() => this.$error('Не удалось обновить общую ТС'))
    },
    highlightField,
    replaceFloatNumbers({ value, prop, afterPoint = null }) {
      this.about_wares[prop] = normalizeFloat({ value, afterPoint });
    },
    togglePin() {
      this.$store.commit("ui/TOGGLE_PIN", this.pinName);
    },
    recalculateCustomsCost() {
      const requests = this.requests;
      Promise.all(requests)
        .then(() => {
          return this.$store.dispatch(
            "transit/recalculateCustomsCost",
            this.selected.id
          );
        })
        .catch(() => {
          this.$snackbar({ text: "Ошибка", color: "red", top: false });
        });
    },
    setFields() {
      const { about_wares } = this.selected.ware_shipments[this.shipmentIndex]
      const {about_wares_json} = about_wares
      Object.keys(about_wares).forEach(key => {
        if (key in this.about_wares) {
          this.about_wares[key] = about_wares[key];
        }
      })
      Object.keys(about_wares_json).forEach(key => {
        if (key in this.about_wares_json) {
          this.about_wares_json[key] = about_wares_json[key];
        }
      })
    },
    uploadData() {
      const about_wares = this.convertEmptyStringsToNull({
        ...this.about_wares,
        about_wares_json:{
          ...this.about_wares_json,
          dispatch_country_name: getCountryNameByCode(this.countries, this.about_wares_json.dispatch_country_letter),
          destination_country_name: getCountryNameByCode(this.countries, this.about_wares_json.destination_country_letter)
        }
      });

      return this.$store.dispatch("transit/uploadShipmentBlockData", {
        name: "about_wares",
        value: about_wares,
      });
    },
  },
};
</script>

<style scoped>
table,
tbody,
tr,
th,
td {
  background-color: #ededed !important;
}
th {
  border-bottom: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
}
td {
  border-bottom: transparent !important;
  padding: 0 10px 10px 0 !important;
}
table tr:last-child td {
  padding: 0 10px 4px 0 !important;
}
.v-data-table__wrapper {
  padding-bottom: 0 !important;
}
.position-relative {
  position: relative;
}
.document-add {
  position: absolute;
  bottom: 7%;
  right: 24%;
}
.root-width {
  width: 1440px;
}
.pinned {
  position: fixed;
  top: 0;
  right: 50%;
  width: 100vw;
  transform: translateX(50%);
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 5;
}
</style>
