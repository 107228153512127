import store from "@/store"
import router from "@/router"


function _toggle(){
  if(!store.getters['ui/getPinned'].includes("payment-errors")){
    store.commit('ui/TOGGLE_PIN', "payment-errors")
  }
}

function _error(text){
  this.$snackbar({text, color:'red', top:false})
}

function _calculate(){
  const id = router.currentRoute.params.id
  store.dispatch('transit/calculatePayments')
    .then(() => {
      store.dispatch("transit/getDocumentById", id)
    })
    .catch(() => _error('Ошибка при расчете платежа"'))
}

function validatePayments(){
  const requests = store.getters['transit/getRequests']
  Promise.all(requests).then(() => {
    const id = router.currentRoute.params.id
    return store.dispatch('transit/validatePayments', id)
  })
    .then(res => {
      res.data && !res.data.length
        ? _calculate()
        : _toggle()  
      if(!res.data.length){
        this.$scrollTo('#transit-warranty', 200, {force: true})
      }
    })
    .catch(() => _error('Ошибка при валидации документа'))
}

export {validatePayments}