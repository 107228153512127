<template>
  <v-col cols="12">
    <div
      class="statistics-box pa-4"
      :class="{'blocked-box' : blocked_visibility}"
    >
      <v-row
        :id="block_id"
        v-click-outside="triggerOnFocus"
      >
        <v-col
          cols="12"
        >
          <v-row>
            <v-col
              cols="9"
              class="pb-0"
            >
              <div class="font-weight-bold d-flex align-center mt-2">
                Перевозчик
                <v-menu offset-x>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      class="mx-1 mr-4"
                      tabindex="-1"
                      small
                      dense
                      v-bind="attrs"
                      v-on="on"
                      @click="onMenuOpen"
                    >
                      mdi-tune
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item> Поиск в справочнике</v-list-item>
                    <v-list-item @click="show = true">
                      Сохранить перевозчика в справочник
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-switch
                  v-model="carrier_json.individual"
                  hide-details="auto"
                  dense
                  color="#5CB7B1"
                >
                  <template #label>
                    <label style="font-size: 12px">Физ. лицо</label>
                  </template>
                </v-switch>
              </div>
            </v-col>
            <v-col
              cols="3"
              class="d-flex justify-end align-center"
            >
              <v-autocomplete
                v-model="search"
                class="ml-16"
                :loading="loading"
                :readonly="loading"
                :items="carriers"
                item-text="own_name"
                :filter="filterBySearchField"
                placeholder="Поиск в справочнике"
                auto-select-first
                return-object
                append-icon="mdi-magnify"
                background-color="white"
                dense
                outlined
                hide-details="true"
                tabindex="-1"
                @update:search-input="fetchCarriers"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="1"
              class="pb-0"
            >
              <label>
                Страна
                <v-autocomplete
                  ref="country_letter"
                  v-model="carrier_json.country_letter"
                  auto-select-first
                  outlined
                  :filter="filterBySearchField"
                  dense
                  hide-details="auto"
                  background-color="white"
                  :items="countries"
                  item-text="letterCode"
                  item-value="letterCode"
                />
              </label>
            </v-col>
            <v-col
              v-show="carrier_json.individual"
              cols="2"
              class="pb-0"
            >
              <label>
                Личный номер
                <v-text-field
                  ref="personal_number"
                  v-model="carrier_json.personal_number"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  @change="checkIsForbiddenPerson"
                />
              </label>
            </v-col>
            <v-col
              v-show="carrier_json.individual"
              cols="2"
              class="pb-0"
            >
              <label>
                Номер документа
                <v-text-field
                  ref="carrier_identity_doc_number"
                  v-model="carrier.carrier_identity_doc_number"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              v-show="!carrier_json.individual"
              cols="2"
              class="pb-0"
            >
              <label>
                Уникальный номер
                <v-text-field
                  ref="carrier_inn"
                  v-model="carrier.carrier_inn"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  @change="checkIsForbiddenOrganization"
                />
              </label>
            </v-col>
            <v-col
              v-show="!carrier_json.individual"
              cols="3"
              class="pb-0"
            >
              <label>
                Наименование
                <v-text-field
                  ref="carrier_name"
                  v-model="carrier.carrier_name"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              v-show="carrier_json.individual"
              cols="2"
              class="pb-0 px-0"
            >
              <label>
                Дата документа
                <custom-date-picker
                  ref="identity_doc_date"
                  v-model="carrier_json.identity_doc_date"
                  :visited.sync="fields_been_visited"
                  :has-changes.sync="hasChanges"
                />
              </label>
            </v-col>
            <v-col
              v-if="!contacts.length"
              class="d-flex align-end justify-end mb-1 pb-0"
              :offset="carrier_json.individual ? 2 : 4"
            >
              <v-btn
                dense
                class="elevation-0"
                @click="addContactItem"
              >
                <v-icon>mdi-plus</v-icon>
                Контакт
              </v-btn>
            </v-col>
            <v-col
              v-if="contacts.length"
              ref="contacts"
              :offset="carrier_json.individual ? 1 : 2"
              cols="4"
            >
              <single-contact
                v-for="(contact, index) in contacts"
                :ref="'cont_' + index"
                :key="index"
                :types="contact_types"
                :item="contact"
                :idx="index"
                :contacts-length="contacts.length"
                @deleteContactItem="deleteContactItem"
                @addContactItem="addContactItem"
                @contactsUpdater="contactsUpdater"
              />
            </v-col>

            <v-col
              v-show="carrier_json.individual"
              cols="2"
              class="pb-0"
            >
              <label>
                Фамилия
                <v-text-field
                  ref="carrier_last_name"
                  v-model="carrier.carrier_last_name"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              v-show="carrier_json.individual"
              cols="2"
              class="pb-0"
            >
              <label>
                Имя
                <v-text-field
                  ref="carrier_first_name"
                  v-model="carrier.carrier_first_name"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              v-show="carrier_json.individual"
              cols="2"
              class="pb-0"
            >
              <label>
                Отчество
                <v-text-field
                  ref="carrier_middle_name"
                  v-model="carrier.carrier_middle_name"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              v-show="carrier_json.individual"
              cols="6"
            />
            <v-col
              cols="1"
              class="pb-0"
            >
              <label>
                Индекс
                <v-text-field
                  ref="postal_index"
                  v-model="carrier_json.postal_index"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="3"
              class="pb-0"
            >
              <label>
                Область/Район
                <v-text-field
                  ref="region"
                  v-model="carrier_json.region"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="2"
              class="pb-0"
            >
              <label>
                Город/Деревня
                <v-text-field
                  ref="city"
                  v-model="carrier_json.city"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="3"
              class="pb-0"
            >
              <label>
                Улица
                <v-text-field
                  ref="street_house"
                  v-model="carrier_json.street_house"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="1"
              class="pb-0"
            >
              <label>
                Дом
                <v-text-field
                  ref="house"
                  v-model="carrier_json.house"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="2"
              class="pb-0"
            >
              <label>
                Номер помещения
                <v-text-field
                  ref="room"
                  v-model="carrier_json.room"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
          </v-row>
        </v-col>
        <v-col align-self="end">
          <v-btn
            dense
            class="mb-1 elevation-0"
            @click="copyToDeclarant"
          >
            <v-icon>mdi-content-copy</v-icon>
            в Декларант
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <archive
      :show.sync="show"
      entity="carrier"
      @save="saveToArchive"
    >
      <v-text-field
        v-model="carrierArchive.certificate_number"
        placeholder="Введите номер сертификата"
        class="px-4 py-2"
        dense
        outlined
        hide-details="true"
      />
      <custom-date-picker
        v-model="carrierArchive.certificate_date"
        class="px-4"
        dense
        outlined
      />
    </archive>
  </v-col>
</template>

<script>
import CustomDatePicker from "@/components/shared/custom-date-picker.vue";
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import {getCountryNameByCode} from "@/helpers/catalogs";
import {mapGetters} from "vuex";
import Archive from "@/components/transit/contragents/archive.vue";
import cloneDeep from "lodash.clonedeep";
import SingleContact from "@/components/transit/single-contact.vue";
import {highlightField} from "@/helpers/control";
import {carrier as onCarrier} from "@/events/statistics/control";
import blockVisibility from '@/mixins/block-visibility'
import {convertEmptyStringsToNull} from "@/helpers/objects";
import {checkContacts} from "@/components/transit/regexp";
import {checkIsForbidden} from "@/helpers/risks";

export default {
  components: {SingleContact, CustomDatePicker, Archive},
  mixins: [filterBySearchFieldMixin, blockAutoUpdate, blockVisibility],
  data: () => ({
    block_id: "transit-carrier",
    promises: [],
    carrierArchive: {
      certificate_number: null,
      certificate_date: null
    },
    collapsedDriver: false,
    carrier: {
      declaration_id: null,
      carrier_inn: "",
      carrier_name: "",
      carrier_first_name: "",
      carrier_last_name: "",
      carrier_middle_name: "",
      carrier_identity_doc_number: "",
    },
    carrier_json: {
      individual: false,
      country_letter: "",
      country_name: "",
      postal_index: "",
      region: "",
      city: "",
      street_house: "",
      house: "",
      room: "",
      personal_number: "",
      identity_doc_date: "",
    },
    contacts: [],
    // поиск в справочнике
    search: null,
    loading: false,
    carriers: [],
    show: false,
  }),
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      selected: "transit/getSelected",
      countries: "catalogs/getNsiCountries",
      contact_types: "catalogs/getContactTypes",
    }),
    divisionId() {
      return this.user?.user?.activeDivisionId;
    },
  },
  watch: {
    search(nv) {
      if (nv && Object.keys(nv).length) {
        this.updateFieldsOnSearch(nv);
        this.search = null;
        this.carriers = [];
        this.forceUpload();
      }
    },
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    },
  },
  created() {
    onCarrier.subscribe(this.highlightField);
  },
  beforeDestroy() {
    onCarrier.unsubscribe();
  },
  methods: {
    highlightField,
    checkIsForbiddenOrganization(identity) {
      checkIsForbidden.call(this, {
        identity,
        promises: this.promises,
        dataModel: "carrier.carrier_inn",
      })
    },
    checkIsForbiddenPerson(identity) {
      checkIsForbidden.call(this, {
        identity,
        promises: this.promises,
        dataModel: "carrier_json.personal_number",
        isPerson: true,
      })
    },
    saveToArchive(own_name) {
      const payload = {
        ...this.carrierArchive,
        id: null,
        own_name: own_name,
        division_id: this.divisionId,
        root_parent_division_id: null,
        name: this.carrier.carrier_name,
        inn: this.carrier.carrier_inn,
        country_letter: this.carrier_json.country_letter,
        country_name: this.carrier_json.country_name,
        region: this.carrier_json.region,
        city: this.carrier_json.city,
        street: this.carrier_json.street_house,
        house: this.carrier_json.house,
        room: this.carrier_json.room,
        postal_index: this.carrier_json.postal_index,
        contacts_json: [],
        branch_name: null,
        branch_inn: null,
        branch_country_letter: null,
        branch_country_name: null,
        branch_region: null,
        branch_city: null,
        branch_street: null,
        branch_house: null,
        branch_room: null,
        branch_postal_index: null,
        branch_contacts_json: [],
      };

      this.$store
        .dispatch("catalogs/saveCarrier", payload)
        .then(() => {
          this.$snackbar({
            text: "Справочник обновлен",
            color: "blue",
            top: false,
            right: false,
          });
        })
        .catch((err) => {
          if (err.response.status === 400) {
            this.$snackbar({
              text: err.response.data || "Ошибка",
              color: "red",
              top: false,
              right: false,
            });
          } else {
            this.$snackbar({
              text: "Ошибка",
              color: "red",
              top: false,
              right: false,
            });
          }
        });
    },
    updateFieldsOnSearch(item) {
      this.carrier.carrier_inn = item.inn;
      this.carrier.carrier_name = item.name;

      Object.keys(this.carrier_json).forEach((key) => {
        if (key in item) {
          this.carrier_json[key] = item[key];
        }
      });
      this.carrier_json.street_house = item.street;
    },
    addContactItem() {
      this.contacts.push({
        code: "",
        name: "",
        number: "",
      });
      this.readyToUpdate();
    },
    deleteContactItem(index) {
      this.contacts.splice(index, 1);
      this.readyToUpdate();
    },
    readyToUpdate() {
      this.hasChanges = true;
      this.fields_been_visited = true;
    },
    getContactsWithName() {
      return this.contacts.map((item) => {
        const {name} =
        this.contact_types.find((i) => i.code === item.code) ?? {};
        return {
          ...item,
          name,
        };
      });
    },
    contactsUpdater(payload) {
      this.contacts[payload.index] = payload.cont_item;
      this.readyToUpdate();
    },
    setFields() {
      const carrier = this.selected.carrier;
      const carrier_json = this.selected.carrier.carrier_json;

      const contacts = carrier_json?.contacts ?? [];
      this.contacts = cloneDeep(contacts);

      Object.keys(this.carrier).forEach((key) => {
        if (key in carrier) {
          this.carrier[key] = carrier[key];
        }
      });

      Object.keys(this.carrier_json).forEach((key) => {
        if (key in carrier_json) {
          this.carrier_json[key] = carrier_json[key];
        }
      });
    },
    forceUpload() {
      return this.uploadData()
        .then((res) => {
          this.$snackbar({
            text: "Обновлено [Перевозчик]",
            color: "green",
            top: false,
            right: false,
          });
          return res;
        })
        .catch((err) => {
          this.$snackbar({
            text: "Ошибка",
            color: "red",
            top: false,
            right: false,
          });
          return Promise.reject(err);
        });
    },
    onMenuOpen() {
      if (this.hasChanges && this.fields_been_visited) {
        this.forceUpload().then(() => {
          this.fields_been_visited = false;
          this.hasChanges = false;
        });
      }
    },
    uploadData() {
      return Promise.all(this.promises).then(() => {
        checkContacts.call(this, this.contacts)
        const carrier = this.convertEmptyStringsToNull({
          ...this.carrier,
          carrier_inn: this.carrier_json.individual ? null : this.carrier.carrier_inn,
          carrier_name: this.carrier_json.individual ? null : this.carrier.carrier_name,
          carrier_first_name: this.carrier_json.individual ? this.carrier.carrier_first_name : null,
          carrier_last_name: this.carrier_json.individual ? this.carrier.carrier_last_name : null,
          carrier_middle_name: this.carrier_json.individual ? this.carrier.carrier_middle_name : null,
          carrier_identity_doc_number: this.carrier_json.individual ? this.carrier.carrier_identity_doc_number : null,
          carrier_json: {
            ...this.carrier_json,
            personal_number: this.carrier_json.individual ? this.carrier_json.personal_number : null,
            identity_doc_date: this.carrier_json.individual ? this.carrier_json.identity_doc_date : null,
            country_name:
                getCountryNameByCode(
                  this.countries,
                  this.carrier_json.country_letter
                ) || null,
            contacts: this.contacts,
          },
        });
        return this.$store.dispatch("transit/uploadBlockData", {
          name: "carrier",
          value: carrier,
        });
      })
    },
    updateDeclarant() {
      const principal = this.selected.principal
      const principal_json = principal.principal_json
      const combined = {
        ...principal
      }
      const combined_json = {
        ...principal_json,
      }
      Object.keys(principal).filter(k => k !== 'principal_json').forEach(key => {
        const original = key.replace('principal_', 'carrier_')
        if (original in this.carrier) {
          combined[key] = this.carrier[original]
        }
      })
      Object.keys(principal_json).forEach(key => {
        if (key in this.carrier_json) {
          combined_json[key] = this.carrier_json[key]
        }
      })
      const payload = convertEmptyStringsToNull({
        ...combined,
        principal_json: {
          ...combined_json,
          contacts: this.getContactsWithName()
        }
      })
      this.$store.dispatch("transit/uploadBlockData", {
        name: "principal",
        value: payload,
      }).then(() => {
        this.$snackbar({
          text: "Обновлено [Декларант]",
          color: "green",
          top: false,
        });
      })
        .catch(() => {
          this.$snackbar({
            text: "Ошибка сохранения блока Декларант",
            color: "red",
            top: false,
          });
        });
    },
    copyToDeclarant() {
      this.uploadData().then(() => {
        this.updateDeclarant()
      }).catch(() => {
        this.$snackbar({
          text: "Ошибка сохранения блока Перевозчик",
          color: "red",
          top: false,
        });
      });
    },
    fetchCarriers(value) {
      if (value && value.length > 3) {
        this.loading = true;
        this.$store
          .dispatch("catalogs/searchCarrier", {
            value,
            divisionId: this.divisionId,
          })
          .then((res) => {
            this.loading = false;
            const items = res.data.map((i) => {
              return {
                ...i,
                search: `${i.name}-${i.own_name}-${i.unp || i.inn}-${i.city}-${
                  i.street
                }`,
              };
            });
            this.carriers = [...items];
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
