<template>
  <div class="shipment-block my-3">
    <goods-character v-if="!pinned.includes('goods-character')" />
    <destination-customs />
    <contragent />
    <documents v-if="!pinned.includes('documents')" />
    <transit-goods />
  </div>
</template>
<script>
import GoodsCharacter from "@/components/transit/goods-character.vue";
import Documents from "@/components/transit/documents/documents.vue"
import TransitGoods from "@/components/transit/goods/transit-goods.vue";
import Contragent from "@/components/transit/contragents/contragent.vue";
import DestinationCustoms from "@/components/transit/destination-customs.vue";
import {mapGetters} from "vuex";

export default {
  components:{
    DestinationCustoms,
    Contragent,
    Documents,
    GoodsCharacter,
    TransitGoods,
  },
  computed:{
    ...mapGetters({
      pinned:'ui/getPinned'
    })
  }
}
</script>
