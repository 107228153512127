<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row
          class="my-auto mx-0 statistics-box"
        >
          <v-col
            :id="block_id"
            v-click-outside="triggerOnFocus"
            cols="6"
          >
            <div class="mb-4">
              <v-tooltip
                v-for="(btn, idx) in visibleActions"
                :key="idx"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="mr-2"
                    min-width="36px"
                    max-width="36px"
                    v-bind="attrs"
                    :disabled="btn.loading"
                    :loading="btn.loading"
                    v-on="on"
                    @click="callFunction(btn.action, { action: btn.action })"
                  >
                    <v-icon :color="btn.color || ''">
                      {{ btn.icon }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ btn.name }}</span>
              </v-tooltip>
            </div>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  ref="out_number"
                  v-model="about_declaration.out_number"
                  outlined
                  dense
                  :error="about_declaration.out_number?.length > 50"
                  maxlength="50"
                  hide-details="auto"
                  :background-color="
                    blocked_visibility ? 'grey lighten-2' : 'white'
                  "
                  :disabled="blocked_visibility"
                />
              </v-col>
              <p class="slash">
                /
              </p>
              <v-col>
                <v-text-field
                  ref="remark"
                  v-model="about_declaration.remark"
                  outlined
                  dense
                  hide-details="auto"
                  class="remark"
                  :background-color="
                    blocked_visibility ? 'grey lighten-2' : 'white'
                  "
                  :disabled="blocked_visibility"
                  placeholder="Примечание"
                />
              </v-col>
              <v-col
                cols="3"
                class="pb-0"
              >
                <custom-date-picker
                  ref="declaration_date"
                  v-model="about_declaration.declaration_date"
                  :visited.sync="fields_been_visited"
                  :has-changes.sync="hasChanges"
                  :background-color="
                    blocked_visibility ? 'grey lighten-2' : 'white'
                  "
                  :disabled="blocked_visibility"
                  @handleChange="suggestRecalc"
                />
              </v-col>
            </v-row>
          </v-col>
          <document-control
            v-if="!pinned.includes('controls')"
            source="transit/getControl"
            pin-name="controls"
            class="ml-2"
          >
            <template #action>
              <common-errors-action />
            </template>
          </document-control>
        </v-row>
      </v-col>
    </v-row>
    <component
      :is="activeComponent"
      :ref="getReferenceAsync"
      :show.sync="showComponent"
      :params="activeComponentParams"
    />
    <recalculate-suggestion :show.sync="showSuggestion" />
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import CustomDatePicker from "@/components/shared/custom-date-picker";
import DocumentControl from "@/components/shared/document-control.vue";
import CommonErrorsAction from "@/components/transit/controls/common-errors-action.vue";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import blockVisibility from '@/mixins/block-visibility'
import {
  dieIfAlreadySent,
  displayControlResponse,
  downloadFile,
  downloadXml,
  getFilenameFromContentDisposition,
  getXmlString,
  NAMESPACE_REQUIRED,
  performDocumentControl, PTO_REQUIRED,
  SELECTED_REQUIRED,
  sendToEclient, signOnElDocIndicatorCondition,
  USER_REQUIRED,
  validateRequiredParams,
} from "@/helpers/documents";
import {about_declaration as onAboutDeclaration} from "@/events/statistics/control";
import ToolboxMixin from "@/mixins/toolbox.mixin";
import {validatePayments} from './controls/payments'
import RecalculateSuggestion from "@/components/transit//recalculate-suggestion.vue";

export default {
  components: {
    RecalculateSuggestion,
    CustomDatePicker,
    DocumentControl,
    CommonErrorsAction,
  },
  mixins: [blockAutoUpdate, ToolboxMixin, blockVisibility],
  data: () => ({
    block_id: "transit-about-declaration",
    namespace: "transit",
    actions: [
      {
        visible: true,
        loading: false,
        icon: "mdi-upload",
        action: "sendDocument",
        name: "Отправить в ИСТО",
      },
      {
        visible: true,
        icon: "mdi-file-pdf-box",
        action: "downloadPdf",
        name: "Скачать PDF",
      },
      {
        visible: true,
        loading: false,
        icon: "mdi-file-xml-box",
        action: "downloadXml",
        name: "Скачать XML",
      },
      {
        visible: false,
        loading: false,
        icon: "",
        action: "showXsdControlResponse",
        name: "Контроль XSD",
        component: () =>
          import("@/components/documents/xsd-control-response.vue"),
      },
      {
        visible: false,
        loading: false,
        icon: "",
        action: "showDocumentControlResponse",
        name: "Контроль FLK",
        component: () =>
          import("@/components/shared/document-control-response.vue"),
      },
      {
        visible: false,
        icon: "",
        action: "showDocumentControlPto",
        name: "Контроль ПТО",
        component: () => import("@/components/shared/document-control-pto.vue"),
      },
    ],
    about_declaration: {
      declaration_date: null,
      declaration_id: null,
      out_number: "",
      remark: "",
    },
    showSuggestion: false,
  }),
  computed: {
    ...mapGetters({
      selected: "transit/getSelected",
      pinned: "ui/getPinned",
      user: "auth/getUser",
      requests: "transit/getRequests",
    }),
    selectedPto() {
      return this.selected?.registration_details?.dispatch_customs_code ?? null;
    }
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    },
  },
  created() {
    onAboutDeclaration.subscribe(this.highlightField);
  },
  beforeDestroy() {
    onAboutDeclaration.unsubscribe();
  },
  methods: {
    dieIfAlreadySent,
    validateRequiredParams,
    performDocumentControl,
    displayControlResponse,
    getXmlString,
    sendToEclient,
    signOnElDocIndicatorCondition,
    updateRegistrationDetails({code, name}) {
      const registration_details_default = this.selected.registration_details
      const registration_details = {
        ...registration_details_default,
        dispatch_customs_code: code,
        dispatch_customs_name: name
      }
      return this.$store.dispatch("transit/uploadBlockData", {
        name: "registration_details",
        value: registration_details,
      });
    },
    fetchDocument(){
      return this.$store.dispatch("transit/getDocumentById", this.selected.id)
    },
    sendDocument() {
      this.setLoading('sendDocument', true)
      this.dieIfAlreadySent()
        .then(this.showDocumentControlPto)
        .then(this.updateRegistrationDetails)
        .then(this.performDocumentControl)
        .then(this.displayControlResponse)
        .then(() => this.validateRequiredParams({
          ...NAMESPACE_REQUIRED,
          ...USER_REQUIRED,
          ...SELECTED_REQUIRED,
          ...PTO_REQUIRED
        }))
        .then(this.getXmlString)
        .then(this.signOnElDocIndicatorCondition)
        .then(this.sendToEclient)
        .then(this.fetchDocument)
        .catch(() => {})
        .finally(() => this.setLoading('sendDocument', false))
    },
    validatePayments,
    suggestRecalc() {
      this.showSuggestion = true
    },
    setFields() {
      const {about_declaration} = this.selected || {};
      for (let key in about_declaration) {
        if (key in this.about_declaration) {
          this.about_declaration[key] = about_declaration[key];
        }
      }
    },
    downloadXml() {
      this.$store.dispatch("transit/getXml", this.selected.id).then((res) => {
        downloadXml(res.data, getFilenameFromContentDisposition(res));
      });
    },
    uploadData() {
      const about = this.convertEmptyStringsToNull(this.about_declaration);
      return this.$store.dispatch("transit/uploadBlockData", {
        name: "about_declaration",
        value: about,
      });
    },
    downloadPdf() {
      this.$store
        .dispatch("transit/downloadPdf", this.selected.id)
        .then((res) => {
          downloadFile({
            data: res.data,
            type: "application/pdf",
            name: getFilenameFromContentDisposition(res),
          });
        })
        .catch(() => this.$error('Ошибка'))
    }
  },
};
</script>

<style scoped>
.burger span {
  display: block;
  background: #333333;
  height: 2px;
  width: 100%;
  margin: 10px 0;
}

.slash {
  display: block;
  text-align: center;
  height: 100%;
  margin: auto 0;
}
</style>
