<template>
  <div>
    <navigation-aside />
    <shipment
      v-if="shipmentIndex !== null"
    />
  </div>
</template>
<script>
import Shipment from "@/components/transit/shipments/shipment.vue";
import {mapGetters} from "vuex";
import NavigationAside from "@/components/transit/shipments/navigation-aside.vue";
import {ware_shipments as onShipments} from "@/events/statistics/control";
import {getFirstValueFromMap, nextTo, removeFirstFromMap} from "@/helpers/control";

export default {
  components: {
    NavigationAside,
    Shipment,
    // Actions,
  },
  computed: {
    ...mapGetters({
      selected: "transit/getSelected",
      shipmentIndex: "transit/getShipmentIndex",
      requests:"transit/getRequests",
    }),
  },
  created() {
    onShipments.subscribe(this.onControl)
  },
  beforeDestroy() {
    onShipments.unsubscribe()
  },
  methods: {
    onControl({path}) {
      const value = getFirstValueFromMap(path)
      if(!Number.isNaN(Number.parseInt(value))) {
        Promise.all(this.requests).then(() => {
          this.$store.commit('transit/SET_SHIPMENT_INDEX', getFirstValueFromMap(path) - 1)
          removeFirstFromMap(path)
          nextTo(path)
        })
      }
    },
  }
}
</script>
<style scoped>
.shipment-block {
  border-left: 2px solid #4db6ac;
}

</style>
