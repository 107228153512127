<template>
  <v-col cols="12">
    <div
      :id="block_id"
      v-click-outside="triggerOnFocus"
      class="statistics-box pa-4"
      :class="{'blocked-box' : blocked_visibility}"
    >
      <v-row>
        <v-col
          :class="{'pb-5':principal_json.individual}"
          cols="12"
        >
          <v-row>
            <v-col>
              <div class="font-weight-bold d-flex align-center mt-2">
                Декларант
                <v-menu offset-x>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      class="mx-1 mr-4"
                      tabindex="-1"
                      small
                      dense
                      v-bind="attrs"
                      v-on="on"
                      @click="onMenuOpen"
                    >
                      mdi-tune
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item> Поиск в справочнике</v-list-item>
                    <v-list-item @click="show = true">
                      Сохранить перевозчика в справочник
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-switch
                  v-model="principal_json.individual"
                  hide-details="auto"
                  dense
                  color="#5CB7B1"
                  @change="onIndividualChange"
                >
                  <template #label>
                    <label style="font-size: 12px">Физ. лицо</label>
                  </template>
                </v-switch>
              </div>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-end align-center"
            >
              <v-autocomplete
                v-model="search"
                class="ml-16"
                :loading="loading"
                :readonly="loading"
                :items="carriers"
                item-text="own_name"
                :filter="filterBySearchField"
                placeholder="Поиск в справочнике"
                auto-select-first
                return-object
                append-icon="mdi-magnify"
                background-color="white"
                dense
                outlined
                hide-details="true"
                tabindex="-1"
                @update:search-input="fetchCarriers"
              />
              <div class="d-flex justify-end">
                <v-btn
                  min-width="32px"
                  class="ml-2"
                  text
                  @click="collapsed = !collapsed"
                >
                  <v-icon>
                    {{ collapsed ? "mdi-chevron-down" : "mdi-chevron-up" }}
                  </v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row v-show="!collapsed">
            <v-col cols="1">
              <label>
                Страна
                <v-autocomplete
                  ref="country_letter"
                  v-model="principal_json.country_letter"
                  auto-select-first
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  :items="countries"
                  item-text="letterCode"
                  :filter="filterBySearchField"
                  item-value="letterCode"
                />
              </label>
            </v-col>
            <v-col
              v-show="principal_json.individual"
              cols="2"
              class="pb-0"
            >
              <label>
                Личный номер
                <v-text-field
                  ref="personal_number"
                  v-model="principal_json.personal_number"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              v-show="!principal_json.individual"
              cols="2"
            >
              <label>
                Уникальный номер
                <v-text-field
                  ref="principal_inn"
                  v-model="principal.principal_inn"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              v-show="!principal_json.individual"
              cols="3"
            >
              <label>
                Наименование
                <v-text-field
                  ref="principal_name"
                  v-model="principal.principal_name"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              v-show="principal_json.individual"
              cols="2"
              class="pb-0"
            >
              <label>
                Номер документа
                <v-text-field
                  ref="principal_identity_doc_number"
                  v-model="principal.principal_identity_doc_number"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              v-show="principal_json.individual"
              cols="2"
              class="pb-0 px-0"
            >
              <label>
                Дата документа
                <custom-date-picker
                  ref="identity_doc_date"
                  v-model="principal_json.identity_doc_date"
                  :visited.sync="fields_been_visited"
                  :has-changes.sync="hasChanges"
                />
              </label>
            </v-col>
            <v-col
              v-if="!contacts.length"
              class="d-flex align-end justify-end mb-1"
              :offset=" principal_json.individual ? 2 : 4"
            >
              <v-btn
                dense
                class="elevation-0"
                @click="addContactItem"
              >
                <v-icon>mdi-plus</v-icon>
                Контакт
              </v-btn>
            </v-col>
            <v-col
              v-if="contacts.length"
              :offset=" principal_json.individual ? 1 : 2"
              cols="4"
            >
              <single-contact
                v-for="(contact, index) in contacts"
                :ref="'cont_' + index"
                :key="index"
                phone-mask="+### ## #######"
                :show-hint="false"
                :types="contact_types"
                :item="contact"
                :idx="index"
                :contacts-length="contacts.length"
                @deleteContactItem="deleteContactItem"
                @addContactItem="addContactItem"
                @contactsUpdater="contactsUpdater"
              />
            </v-col>

            <v-col
              v-show="principal_json.individual"
              cols="2"
              class="pb-0"
            >
              <label>
                Фамилия
                <v-text-field
                  ref="principal_last_name"
                  v-model="principal.principal_last_name"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              v-show="principal_json.individual"
              cols="2"
              class="pb-0"
            >
              <label>
                Имя
                <v-text-field
                  ref="principal_first_name"
                  v-model="principal.principal_first_name"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              v-show="principal_json.individual"
              cols="2"
              class="pb-0"
            >
              <label>
                Отчество
                <v-text-field
                  ref="principal_middle_name"
                  v-model="principal.principal_middle_name"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              v-show="principal_json.individual"
              cols="6"
            />
            <v-col
              cols="1"
              class="pb-0"
            >
              <label>
                Индекс
                <v-text-field
                  ref="postal_index"
                  v-model="principal_json.postal_index"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="3"
              class="pb-0"
            >
              <label>
                Область/Район
                <v-text-field
                  ref="region"
                  v-model="principal_json.region"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="2"
              class="pb-0"
            >
              <label>
                Город/Деревня
                <v-text-field
                  ref="city"
                  v-model="principal_json.city"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="3"
              class="pb-0"
            >
              <label>
                Улица
                <v-text-field
                  ref="street_house"
                  v-model="principal_json.street_house"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="1"
              class="pb-0"
            >
              <label>
                Дом
                <v-text-field
                  ref="house"
                  v-model="principal_json.house"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="2"
              class="pb-0"
            >
              <label>
                Номер помещения
                <v-text-field
                  ref="room"
                  v-model="principal_json.room"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
          </v-row>
          <filler-collapsed
            v-if="collapsed"
            :value="principal"
            :json="principal_json"
            :individual="principal_json.individual"
            :contacts="contacts"
          />
        </v-col>
        <!-- Филиал -->
        <v-col
          v-show="!principal_json.individual"
          cols="12"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <div class="font-weight-bold d-flex align-center mt-2 ">
                Филиал
                <v-btn
                  min-width="32px"
                  class="ml-1"
                  text
                  @click="collapsedBranch = !collapsedBranch"
                >
                  <v-icon>
                    {{
                      collapsedBranch ? "mdi-chevron-down" : "mdi-chevron-up"
                    }}
                  </v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row v-show="!collapsedBranch">
            <v-col
              cols="1"
              class="pb-0"
            >
              <label>
                Страна
                <v-autocomplete
                  ref="branch_country_letter"
                  v-model="principal_json.branch_country_letter"
                  auto-select-first
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  :items="countries"
                  item-text="letterCode"
                  item-value="letterCode"
                  :filter="filterBySearchField"
                />
              </label>
            </v-col>
            <v-col
              cols="2"
              class="pb-0"
            >
              <label>
                УНП (ИНН И т.п.)
                <v-text-field
                  ref="principal_branch_inn"
                  v-model="principal.principal_branch_inn"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="3"
              class="pb-0 pr-1"
            >
              <label>
                Наименование организации
                <v-text-field
                  ref="principal_branch_name"
                  v-model="principal.principal_branch_name"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>

            <v-col
              v-if="!branch_contacts.length && !collapsedBranch"
              ref="branch_contacts"
              class="d-flex align-end justify-end mb-1"
              offset="4"
            >
              <v-btn
                dense
                class="elevation-0"
                @click="addContactItem('branch')"
              >
                <v-icon>mdi-plus</v-icon>
                Контакт
              </v-btn>
            </v-col>
            <v-col
              v-if="branch_contacts.length && !collapsedBranch"
              ref="branch_contacts"
              offset="2"
              cols="4"
              class="pb-5"
            >
              <single-contact
                v-for="(contact, index) in branch_contacts"
                :ref="'branch_cont_' + index"
                :key="'branch-'+index"
                :types="contact_types"
                :item="contact"
                :idx="index"
                phone-mask="+### ## #######"
                mask="+### ## #######"
                :contacts-length="branch_contacts.length"
                @deleteContactItem="(idx) => deleteContactItem(idx, 'branch')"
                @addContactItem="addContactItem('branch')"
                @contactsUpdater="(value) => contactsUpdater(value, 'branch')"
              />
            </v-col>
            <v-col
              cols="1"
              class="pb-0"
            >
              <label>
                Индекс
                <v-text-field
                  ref="branch_postal_index"
                  v-model="principal_json.branch_postal_index"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="3"
              class="pb-0"
            >
              <label>
                Область/Район
                <v-text-field
                  ref="branch_region"
                  v-model="principal_json.branch_region"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="2"
              class="pb-0"
            >
              <label>
                Город/Деревня
                <v-text-field
                  ref="branch_city"
                  v-model="principal_json.branch_city"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col cols="3">
              <label>
                Улица
                <v-text-field
                  ref="branch_street_house"
                  v-model="principal_json.branch_street_house"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col cols="1">
              <label>
                Дом
                <v-text-field
                  ref="branch_house"
                  v-model="principal_json.branch_house"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col cols="2">
              <label>
                Номер помещения
                <v-text-field
                  ref="branch_room"
                  v-model="principal_json.branch_room"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
          </v-row>
          <filler-branch-collapsed
            v-if="collapsedBranch"
            :value="principal"
            :json="principal_json"
            :contacts="branch_contacts"
          />
        </v-col>
      </v-row>
    </div>
    <archive
      :show.sync="show"
      entity="principal"
      @save="saveToArchive"
    />
  </v-col>
</template>

<script>
import {mapGetters} from "vuex";
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import CustomDatePicker from "@/components/shared/custom-date-picker.vue";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import {getCountryNameByCode} from "@/helpers/catalogs";
import Archive from "@/components/transit/contragents/archive.vue";
import {convertEmptyStringsToNull} from "@/helpers/objects";
import SingleContact from "@/components/transit/single-contact.vue";
import cloneDeep from "lodash.clonedeep";
import blockVisibility from '@/mixins/block-visibility'
import {principal as onPrincipal} from "@/events/statistics/control";
import {highlightField} from "@/helpers/control";
import FillerCollapsed from "@/components/transit/filler-collapsed.vue";
import FillerBranchCollapsed from "@/components/transit/filler-branch-collapsed.vue";
import {checkContacts} from "@/components/transit/regexp";
export default {
  components: {SingleContact, CustomDatePicker, Archive, FillerCollapsed, FillerBranchCollapsed},
  mixins: [filterBySearchFieldMixin, blockAutoUpdate, blockVisibility],
  data: () => ({
    block_id: "transit-principal",
    collapsedBranch: true,
    collapsed:false,
    principal: {
      declaration_id: null,
      principal_inn: null,
      principal_name: null,
      principal_first_name: null,
      principal_last_name: null,
      principal_middle_name: null,
      principal_branch_inn: null,
      principal_branch_name: null,
      principal_identity_doc_number: null
    },
    principal_json: {
      country_letter: null,
      country_name: null,
      postal_index: null,
      region: null,
      city:null,
      street_house: null,
      house: null,
      room: null,
      branch_country_letter: null,
      branch_country_name: null,
      branch_postal_index: null,
      branch_region: null,
      branch_city: null,
      branch_street_house: null,
      branch_house: null,
      branch_room: null,
      personal_number: null,
      identity_doc_date:null,
    },
    contacts: [],
    branch_contacts: [],
    // поиск в справочнике
    search: null,
    loading: false,
    carriers: [],
    show: false,
  }),
  computed: {
    ...mapGetters({
      selected: "transit/getSelected",
      countries: "catalogs/getNsiCountries",
      user: "auth/getUser",
      contact_types: "catalogs/getContactTypes",
    }),
    divisionId() {
      return this.user?.user?.activeDivisionId;
    },
  },
  watch: {
    selected: {
      handler(){
        this.setFields();
      },
      deep:true
    },
    search(nv) {
      if (nv && Object.keys(nv).length) {
        this.updateFieldsOnSearch(nv);
        this.search = null;
        this.carriers = [];
        this.forceUpload();
      }
    },
  },
  created() {
    onPrincipal.subscribe(this.onControl)
  },
  beforeDestroy() {
    onPrincipal.unsubscribe()
  },
  methods: {
    highlightField,
    onIndividualChange(value){
      if(value){
        this.clearBranchInfo()
      }
    },
    clearBranchInfo(){
      Object.keys(this.principal_json).forEach(key => {
        if(key.toString().startsWith('branch')){
          this.principal_json[key] = null
        }
      })
      this.principal.principal_branch_name = null
      this.principal.principal_branch_inn = null
      this.branch_contacts = []
    },
    onControl(data) {
      this.collapsed = false
      this.collapsedBranch = false
      this.$nextTick(() => {
        this.highlightField(data)
      })
    },
    saveToArchive(own_name) {
      const payload = {
        id: null,
        own_name: own_name,
        division_id: this.divisionId,
        root_parent_division_id: null,
        name: this.principal.principal_name,
        inn: this.principal.principal_inn,
        country_letter: this.principal_json.country_letter,
        country_name: this.principal_json.country_name,
        region: this.principal_json.region,
        city: this.principal_json.city,
        street: this.principal_json.street_house,
        house: this.principal_json.house,
        room: this.principal_json.room,
        postal_index: this.principal_json.postal_index,
        contacts_json: [], // TODO: поправить сохранение в справочник
        branch_name: this.principal.principal_branch_name,
        branch_inn: this.principal.principal_branch_inn,
        branch_country_letter: this.principal_json.branch_country_letter,
        branch_country_name: this.principal_json.branch_country_name,
        branch_region: this.principal_json.branch_region,
        branch_city: this.principal_json.branch_city,
        branch_street: this.principal_json.branch_street_house,
        branch_house: this.principal_json.branch_house,
        branch_room: this.principal_json.branch_room,
        branch_postal_index: this.principal_json.branch_postal_index,
        branch_contacts_json: this.branch_contacts,
      };

      this.$store
        .dispatch("catalogs/saveCarrier", payload)
        .then(() => {
          this.$snackbar({
            text: "Справочник обновлен",
            color: "blue",
            top: true,
            right: true,
          });
        })
        .catch((err) => {
          if (err.response.status === 400) {
            this.$snackbar({
              text: err.response.data || "Ошибка",
              color: "red",
              top: false,
              right: false,
            });
          } else {
            this.$snackbar({
              text: "Ошибка",
              color: "red",
              top: false,
              right: false,
            });
          }
        });
    },
    updateFieldsOnSearch(item) {
      this.principal.principal_inn = item.inn;
      this.principal.principal_name = item.name;
      this.principal.principal_branch_inn = item.branch_inn;
      this.principal.principal_branch_name = item.branch_name;

      Object.keys(this.principal_json).forEach((key) => {
        if (key in item) {
          this.principal_json[key] = item[key];
        }
      });
      this.principal_json.branch_street_house = item.branch_street;
      this.principal_json.street_house = item.street;

      const contacts = item.contacts;
      contacts.map((i) => {
        i.number = i.contact;
        delete i.contact;
      });

      const branch_contacts = item.branch_contacts;
      branch_contacts.map((i) => {
        i.number = i.contact;
        delete i.contact;
      });

      this.contacts = contacts;
      this.branch_contacts = branch_contacts
    },
    setFields() {
      const principal = this.selected.principal;
      const principal_json = this.selected.principal.principal_json;

      const contacts = principal_json?.contacts ?? [];
      const branch_contacts = principal_json?.branch_contacts ?? [];
      this.contacts = cloneDeep(contacts);
      this.branch_contacts = cloneDeep(branch_contacts)

      Object.keys(this.principal).forEach((key) => {
        if (key in principal) {
          this.principal[key] = principal[key];
        }
      });
      Object.keys(this.principal_json).forEach((key) => {
        if (key in principal_json) {
          this.principal_json[key] = principal_json[key];
        }
      });
    },
    copyToCarrier() {
      this.uploadData()
        .then(() => {
          this.updateCarrier();
        })
        .catch(() => {
          this.$snackbar({
            text: "Ошибка копирования",
            color: "red",
            top: false,
          });
        });
    },
    contactsUpdater(payload, type) {
      if (type === 'branch') {
        this.branch_contacts[payload.index] = payload.cont_item;
      } else {
        this.contacts[payload.index] = payload.cont_item;
      }
      this.readyToUpdate();
    },
    updateCarrier() {
      const carrier = {
        declaration_id: this.principal.declaration_id,
        carrier_inn: this.principal.principal_inn,
        carrier_name: this.principal.principal_name,
        carrier_first_name: this.principal.principal_first_name,
        carrier_last_name: this.principal.principal_last_name,
        carrier_middle_name: this.principal.principal_middle_name,
        carrier_identity_doc_number:
        this.principal.principal_identity_doc_number,
        carrier_json: this.principal_json,
      };

      const value = convertEmptyStringsToNull(carrier);
      return this.$store.dispatch("transit/uploadBlockData", {
        name: "carrier",
        value,
      });
    },
    forceUpload() {
      return this.uploadData()
        .then((res) => {
          this.$snackbar({
            text: "Обновлено [Принципал]",
            color: "green",
            top: false,
            right: false,
          });
          return res;
        })
        .catch((err) => {
          this.$snackbar({
            text: "Ошибка",
            color: "red",
            top: false,
            right: false,
          });
          return Promise.reject(err);
        });
    },
    onMenuOpen() {
      if (this.hasChanges && this.fields_been_visited) {
        this.forceUpload().then(() => {
          this.fields_been_visited = false;
          this.hasChanges = false;
        });
      }
    },
    uploadData() {
      checkContacts.call(this, this.contacts)
      const principal = this.getPreparedObject()
      return this.$store.dispatch("transit/uploadBlockData", {
        name: "principal",
        value: principal,
      });
    },
    getPreparedObject() {
      return this.convertEmptyStringsToNull({
        ...this.principal,
        principal_inn: this.principal_json.individual ? null : this.principal.principal_inn,
        principal_name: this.principal_json.individual ? null : this.principal.principal_name,
        principal_identity_doc_number: !this.principal_json.individual ? null : this.principal.principal_identity_doc_number,
        principal_last_name: this.principal_json.individual ? this.principal.principal_last_name : null,
        principal_first_name: this.principal_json.individual ? this.principal.principal_first_name : null,
        principal_middle_name: this.principal_json.individual ? this.principal.principal_middle_name : null,
        principal_json: {
          ...this.principal_json,
          identity_doc_date: this.principal_json.individual ? this.principal_json.identity_doc_date : null,
          personal_number: this.principal_json.individual ? this.principal.personal_number : null,
          country_name:
              getCountryNameByCode(
                this.countries,
                this.principal_json.country_letter
              ) || null,
          branch_country_name: getCountryNameByCode(
            this.countries,
            this.principal_json.branch_country_letter
          ),
          contacts: this.getContactsWithName(this.contacts),
          branch_contacts: this.getContactsWithName(this.branch_contacts)
        },
      });
    },
    getContactsWithName(source) {
      return source.map((item) => {
        const {name} =
        this.contact_types.find((i) => i.code === item.code) ?? {};
        return {
          ...item,
          name,
        };
      });
    },
    addContactItem(type) {
      const contact = {
        code: "",
        name: "",
        number: "",
      }
      if (type === 'branch') {
        this.branch_contacts.push(contact);
      } else {
        this.contacts.push(contact);
      }
      this.readyToUpdate();
    },
    deleteContactItem(index, type) {
      if (type === 'branch') {
        this.branch_contacts.splice(index, 1)
      } else {
        this.contacts.splice(index, 1);
      }
      this.readyToUpdate();
    },
    readyToUpdate() {
      this.hasChanges = true;
      this.fields_been_visited = true;
    },
    fetchCarriers(value) {
      if (value && value.length > 3) {
        this.loading = true;
        this.$store
          .dispatch("catalogs/searchCarrier", {
            value,
            divisionId: this.divisionId,
          })
          .then((res) => {
            this.loading = false;
            const items = res.data.map((i) => {
              return {
                ...i,
                search: `${i.name}-${i.own_name}-${i.unp || i.inn}-${i.city}-${
                  i.street
                }`,
              };
            });
            this.carriers = [...items];
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
