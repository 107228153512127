<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div
          class="statistics-box statistics-transport-list pa-4"
          :class="{'blocked-box' : disabledView}"
        >
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col
                  cols="8"
                  class="pb-0"
                  :class="{'blocked-box-exclude':disabledView }"
                >
                  <div
                    class="font-weight-bold d-flex align-center mt-2"
                  >
                    Транспортные средства
                    <v-menu
                      offset-x
                    >
                      <template #activator="{ on, attrs }">
                        <v-icon
                          class="mx-1"
                          tabindex="-1"
                          small
                          dense
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-tune
                        </v-icon>
                      </template>
                      <v-list>
                        <v-list-item
                          @click="openTransportDeclarationModal"
                        >
                          Сформировать ДТС
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </v-col>
              </v-row>
              <v-row
                :id="block_id"
                v-click-outside="triggerOnFocus"
              >
                <v-col cols="1">
                  <label for="">
                    19 Контейнер
                    <v-autocomplete
                      ref="container_indicator"
                      v-model="about_transport.container_indicator"
                      item-text="text"
                      item-value="value"
                      :items="container_types"
                      auto-select-first
                      background-color="white"
                      dense
                      outlined
                      hide-details="true"
                    />
                  </label>
                </v-col>
                <v-col cols="2">
                  <label for="">
                    25 Вид транспорта на границе
                    <v-autocomplete
                      ref="border_transport_type"
                      v-model="about_transport.border_transport_type"
                      item-text="text"
                      item-value="code"
                      :items="transport"
                      auto-select-first
                      background-color="white"
                      dense
                      outlined
                      hide-details="true"
                      :filter="filterByCode"
                    />
                  </label>
                </v-col>
                <v-col
                  offset="3"
                  cols="2"
                >
                  <label>
                    Книжка МДП
                    <v-text-field
                      v-model="about_transport.tir_serial_number"
                      background-color="white"
                      dense
                      outlined
                      hide-details="true"
                    />
                  </label>
                </v-col>
                <v-col cols="2">
                  <label>
                    Номер листа
                    <v-text-field
                      v-model="about_transport.tir_page_number"
                      background-color="white"
                      dense
                      outlined
                      hide-details="true"
                    />
                  </label>
                </v-col>
                <v-col cols="2">
                  <label>
                    ИН держателя
                    <v-text-field
                      v-model="about_transport.tir_owner_inn"
                      background-color="white"
                      dense
                      outlined
                      hide-details="true"
                    />
                  </label>
                </v-col>
              </v-row>
              <v-row
                v-if="transportItems.length"
                ref="common"
              >
                <v-col cols="12">
                  <v-simple-table
                    class="bg-gray"
                    fixed-header
                    dense
                  >
                    <template #default>
                      <thead>
                        <tr>
                          <th>Номер ТС</th>
                          <th>Номер шасси (VIN)</th>
                          <th>Страна рег.</th>
                          <th>Код вида ТС</th>
                          <th>Код марки ТС</th>
                          <th>Номер документа</th>
                          <th>В Графу 18</th>
                          <th>В Графу 21</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        <transport-item
                          v-for="(item, index) in transportItems"
                          :key="index"
                          :item="item"
                          :items-amount="transportItems.length"
                          :countries="countries"
                          :transport="transport"
                          :nsi-transport-marks="nsiTransportMarks"
                          :index="index"
                          :vehicle-types="vehicleTypes"
                          :control="transportControlPath"
                          @addNewTransport="addNewTransport"
                          @deleteTransport="deleteTransport"
                        />
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
              <v-row
                v-if="!transportItems.length"
                ref="common"
              >
                <v-col cols="12">
                  <v-btn
                    text
                    class="px-0"
                    @click="addNewTransport"
                  >
                    <v-icon>mdi-plus</v-icon>
                    Добавить ТС
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <component
      :is="activeComponent"
      namespace="transit"
      :show.sync="show"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import TransportItem from "@/components/transit/transport/transport-item.vue";
import {getScrollOffset, highlightField} from "@/helpers/control";
import { about_transport as onAboutTransport, transports as onTransports } from "@/events/statistics/control";
import blockVisibility from '@/mixins/block-visibility'
import {filterBySearchFieldMixin} from "@/mixins/catalogs";

export default {
  components: { TransportItem },
  mixins: [blockAutoUpdate, blockVisibility, filterBySearchFieldMixin],
  data: () => ({
    block_id: "transit-about-transport",
    activeComponent:null,
    show:false,
    transportItems: [],
    about_transport: {
      border_transport_type: null,
      container_indicator: null,
      declaration_id: null,
      tir_owner_inn: null,
      tir_page_number: null,
      tir_serial_number: null
    },
    container_types: [
      {
        text: '0 - без контейнера',
        value: false,
      },
      {
        text: '1 - с контейнером',
        value: true,
      },
    ],
    transportControlPath: {
      path: null
    }
  }),
  computed: {
    ...mapGetters({
      selected: "transit/getSelected",
      nsiTransportMarks: "transit/getNsiTransportMarks",
      countries: "catalogs/getNsiCountries",
      transport: "catalogs/getNsiTransportTypes",
      vehicleTypes: "catalogs/getNsiVehicleTypes",
      disabledView: "transit/getVisibility"
    }),
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
      // immediate:true
    },
  },
  created() {
    onAboutTransport.subscribe(this.onAboutTransportControl);
    onTransports.subscribe(this.onTransportControl)
  },
  beforeDestroy() {
    onAboutTransport.unsubscribe();
    onTransports.unsubscribe()
  },
  methods: {
    openTransportDeclarationModal(){
      this.activeComponent = () => import('@/components/documents/transport-declaration/transport-declaration-modal.vue')
      this.show = true
    },
    onAboutTransportControl(path){
      const docs = document.querySelector(".statistics-transport-list");
      const offset = getScrollOffset();
      this.$scrollTo(docs, 200, {
        force: true,
        offset,
        onDone: () => {
          highlightField.call(this, path)
        },
      });
    },
    onTransportControl(path){
      const docs = document.querySelector(".statistics-transport-list");
      const offset = getScrollOffset();
      this.$scrollTo(docs, 200, {
        force: true,
        offset,
        onDone: () => {
          this.transportControlPath = path
        },
      });
    },
    filterByCode(item, queryText) {
      return (
        item.code.indexOf(queryText.toLocaleLowerCase()) >
          -1
      );
    },
    uploadData() {
      const about_transport = this.convertEmptyStringsToNull(
        this.about_transport
      );
      return this.$store.dispatch("transit/uploadBlockData", {
        name: "about_transport",
        value: about_transport,
      });
    },
    addNewTransport() {
      this.$store.dispatch("transit/createTransport", this.selected.id);
    },
    deleteTransport(id) {
      this.$store.dispatch("transit/deleteTransport", id);
    },
    setFields() {
      this.transportItems = this.selected.transports;

      const about_transport = this.selected.about_transport;
      Object.keys(this.about_transport).forEach(key => {
        if(key in about_transport){
          this.about_transport[key] = about_transport[key]
        }
      })

    },
  },
};
</script>

<style scoped>
.bg-gray {
  background-color: #ededed !important;
}
thead tr th {
  padding-left: 0 !important;
  background-color: #ededed !important;
  box-shadow: none !important;
}
</style>
