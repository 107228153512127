<template>
  <v-row>
    <v-col>
      <span class="mr-2">{{ value.principal_branch_inn }}</span>

      <span class="mr-2">{{ value.principal_branch_name }}</span>

      <span class="mr-2">{{ address }}</span>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    value: {
      required: true,
      type: Object
    },
    json: {
      required: true,
      type: Object
    },
    contacts: {
      required: true,
      type: Array
    }
  },
  computed: {
    address() {
      const {
        branch_country_name,
        branch_postal_index,
        branch_region,
        branch_city,
        branch_house,
        branch_street_house,
        branch_room
      } = this.json
      const number = this.contacts.length ? this.contacts.at(0)?.number ?? null : null
      return [
        branch_country_name,
        branch_postal_index,
        branch_region,
        (branch_city ? `г.${branch_city}` : null),
        (branch_street_house ? `ул.${branch_street_house}` : null),
        (branch_house ? `д.${branch_house}` : null),
        (branch_room ? `пом.${branch_room}` : null),
        number
      ].filter(i => ![null, ""].includes(i)).join(', ')
    }
  }
}
</script>