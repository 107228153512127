<template>
  <ware-vehicle
    :vehicle-types="vehicleTypes"
    :transport-marks="nsiTransportMarks"
    :vehicle="vehicle"
    @update="onVehicleUpdate"
    @clear="onVehicleClear"
  />
</template>
<script>


import WareVehicle from "@/components/documents/ware/ware-vehicle.vue";
import {mapGetters} from "vuex";
import {patchStateInjector, readyToUpdateInjector} from "@/helpers/injectors";
import cloneDeep from "lodash.clonedeep";

export default {
  components: {WareVehicle},
  inject:{
    readyToUpdate:{
      from: readyToUpdateInjector
    },
    patchState:{
      from: patchStateInjector
    }
  },
  props:{
    index:{
      required:true,
      type:Number
    }
  },
  data(){
    return{
      vehicle: null
    }
  },
  computed:{
    ...mapGetters({
      vehicleTypes: "catalogs/getNsiVehicleTypes",
      selected: "transit/getSelected",
      shipmentIndex:'transit/getShipmentIndex',
      nsiTransportMarks: "transit/getNsiTransportMarks"
    }),
    selectedWares(){
      return this.selected.ware_shipments[this.shipmentIndex]?.wares ?? []
    },
  },
  watch:{
    selectedWares:{
      handler(){
        this.setFields()
      },
      deep:true,
      immediate:true
    },
  },
  methods:{
    onVehicleUpdate(vehicle){
      this.patchState({ware_transport_json:vehicle})
      this.readyToUpdate()
    },
    onVehicleClear(vehicle){
      this.patchState({ware_transport_json:vehicle})
      this.readyToUpdate()
    },
    setFields(){
      const {ware_transport_json} = cloneDeep(this.selectedWares[this.index]);
      this.vehicle = ware_transport_json
    }
  }
}
</script>
