<template>
  <section
    class="pb-16 mx-auto sd-main"
    style="width: 1440px"
  >
    <pinned-template
      :tabs="tabs"
    />
    <transit-toolbox />
    <transit-main-info />
    <transport />
    <reloads />
    <carrier />
    <driver />
    <filler />
    <transit-submitted />

    <shipments />

    <warranty />

    <catalogs />
    <shared-buffer />

    <transit-copy-field-modal />
    <copy-field-to-all-modal />
    <side-menu :items="menuItems" />
    <distinct-division-notification />
    <forbidden-carrier-notification />
  </section>
</template>

<script>
import PinnedTemplate from '../../components/shared/pinned-template.vue'
import TransitToolbox from '@/components/transit/transit-toolbox.vue'
import TransitMainInfo from '@/components/transit/transit-main-info.vue'
import Carrier from '@/components/transit/carrier.vue'
import Transport from '@/components/transit/transport/transport.vue'
import Reloads from '../../components/transit/reloads/reloads.vue'
import Warranty from '../../components/transit/warranty/warranty.vue'
import TransitSubmitted from '../../components/transit/transit-submitted.vue'
import Catalogs from "@/components/catalogs/modals/resolver.vue";
import offsetMixin from '@/mixins/offset.mixin.js'
import Driver from "@/components/transit/driver.vue";
import Shipments from '@/components/transit/shipments/shipments.vue';
import Filler from "@/components/transit/filler.vue";
import SharedBuffer from "@/components/shared/shared-buffer.vue";
import CopyFieldToAllModal from "@/components/documents/copy-field-to-all-modal.vue";
import SideMenu from "@/components/shared/side-menu.vue";
import WaresScroll from "@/components/shared/wares-scroll.vue";
import DistinctDivisionNotification from "@/components/documents/warnings/distinct-division-notification.vue";
import {setDeclarationTitle} from "@/helpers/text";
import {mapGetters} from "vuex";
import TransitCopyFieldModal from "@/components/transit/goods/transit-copy-field-modal.vue";
import ForbiddenCarrierNotification from "@/components/documents/warnings/forbidden-carrier-notification.vue";

export default{
  components: {
    ForbiddenCarrierNotification,
    TransitCopyFieldModal,
    DistinctDivisionNotification,
    SideMenu,
    CopyFieldToAllModal,
    SharedBuffer,
    Filler,
    Shipments,
    Driver,
    PinnedTemplate,
    TransitToolbox,
    TransitMainInfo,
    Carrier,
    Transport,
    Reloads,
    Warranty,
    TransitSubmitted,
    Catalogs
  },
  mixins:[offsetMixin],
  provide:{
    rootNamespace:'transit'
  },
  data:() => ({
    tabs:[
      {
        name:'controls',
        component:() => import('@/components/transit/controls/common-errors.vue')
      },
      {
        name:'documents',
        component:() => import('@/components/transit/documents/documents.vue')
      },
      {
        name:'goods-character',
        component:() => import('@/components/transit/goods-character.vue')
      },
      {
        name:'payment-errors',
        component: () => import('@/components/transit/controls/payment-errors.vue'),
      }
    ],
    menuItems:[
      {
        name:"Основная информация",
        id:"transit-main-info"
      },
      {
        name:"Транспорт",
        id:"transit-about-transport"
      },
      {
        name:"Перегрузки",
        id:"transit-reloads"
      },
      {
        name:"Перевозчик",
        id:"transit-carrier"
      },
      {
        name:"Водитель",
        id:"transit-driver"
      },
      {
        name:"Декларант",
        id:"transit-principal"
      },
      {
        name:"Представленный",
        id:"transit-filler"
      },
      null,
      {
        name: "Общие характеристики товара",
        id:"transit-goods-character"
      },
      {
        name:"Орган назначения",
        id:"transit-destination-customs"
      },
      {
        name:"Отправитель",
        id:"transit-sender"
      },
      {
        name:"Получатель",
        id:"transit-recipient"
      },
      {
        name:"Документы",
        id:"transit-presented-documents"
      },
      {
        name:"Товары",
        id:"transit-wares",
        component:WaresScroll,
      },
      null,
      {
        name:"Гарантии",
        id:"transit-guarantee"
      }
    ]
  }),
  computed: {
    ...mapGetters({
      selected: "transit/getSelected",
    }),
  },
  watch:{
    selected:{
      handler(transit){
        const {remark, out_number: outNumber} = transit?.about_declaration ?? {}
        setDeclarationTitle({
          outNumber,
          remark,
          prefix:"ТД"
        })
      },
      deep:true
    }
  },
  created(){
    this.$store.dispatch("catalogs/fetchAll")
    this.$store.dispatch('transit/fetchNsiAeos')
    this.$store.dispatch('transit/fetchNsiTransportMarks')
    const id = this.$route.params.id
    this.$store.dispatch("transit/getDocumentById", id).catch(err => {
      if(err.response.status === 403){
        this.$router.push('/403')
      }
    });

  },
}
</script>
