var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"goods-description__block",attrs:{"cols":"12"}},[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"tabindex":"-1"}},[_vm._v(" Описание товаров ")]),_c('v-tab',{staticClass:"ware-show-list-tab",attrs:{"disabled":!!_vm.vehicleTextLength,"tabindex":"-1"}},[_vm._v(" Добавить товары списком ")]),_c('v-tab',{staticClass:"ware-show-list-tab",attrs:{"disabled":!!_vm.wareDetails.length,"tabindex":"-1"}},[_vm._v(" Автомобиль ")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{staticClass:"tab-grey"},[_c('v-textarea',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTop),expression:"showTop"}],staticClass:"textarea-top",class:{
          'textarea-top-error': _vm.totalValue > 1000
        },attrs:{"placeholder":"Значения из списка","rows":_vm.topRows,"loading":"false","no-resize":"","value":_vm.calculatedDescription,"full-width":"","background-color":"white","hide-details":"true","readonly":""},on:{"click":_vm.openDetailTab}}),_c('div',{staticClass:"goods-description__text-wrapper"},[_c('v-textarea',{staticClass:"description-text",class:{
            'textarea-bottom-dashed' : _vm.showTop,
            'textarea-bottom-solid': !_vm.showTop,
            'textarea-error': _vm.totalValue > 1000
          },attrs:{"value":_vm.item.description,"placeholder":"Описание","counter":1000,"counter-value":_vm.total,"rows":_vm.bottomRows,"no-resize":"","loading":"false","full-width":"","background-color":"white","hide-details":"auto"},on:{"input":(nv) => _vm.$emit('input', nv)}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.add_goods_by_list),expression:"!add_goods_by_list"}],staticClass:"goods-description__action"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"tabindex":"-1","min-width":"36px","max-width":"36px","color":"#5CB7B1"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-tune")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.copyToAll('description', _vm.item.description )}}},[_vm._v(" Применить ко всем товарам ")]),_c('v-list-item',{on:{"click":function($event){return _vm.copy('description', _vm.item.description, 'apply')}}},[_vm._v(" Применить к выбранным товарам ")]),_c('v-list-item',{on:{"click":function($event){return _vm.googleImage(_vm.item.description)}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-magnify")]),_vm._v(" Поиск в Google ")],1)],1)],1)],1)],1)],1)],1),_c('v-tab-item',{attrs:{"eager":""}},[_c('table-goods-list',{attrs:{"ware-details":_vm.wareDetails,"show-quantity":_vm.showQuantity,"item":_vm.item,"index":_vm.index},on:{"update":(nv) => (_vm.waresListText = nv)}})],1),_c('v-tab-item',{attrs:{"eager":""}},[_c('vehicle',{attrs:{"index":_vm.index}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }